function mask_cpf(value) {

    if (!value) return;

    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

function mask_cnpj(value) {

    if (!value) return;

    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

function mask_cep(value) {

    if (!value) return;

    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');
}

function mask_phone(value) {

    if (!value) return;

    if (value.length === 11) {

        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');

    } else if (value.length === 10) {
            
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    
    } else {
        return value;
    }

}

function mask_money(value) {

    if (value === 0) return 'R$ 0,00';

    if (!value) return;

    value = parseFloat(value);

    return value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
}

function mask_input_money(event) {

    let input = event.target
    let money = event.target.value
    let length = money.length

    money = money.replace(/(\D)/g, '')

    if (length >= 11) return input.value = money.replace(/^(\d{1})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3,$4')
    if (length >= 10) return input.value = money.replace(/^(\d{3})(\d{3})(\d{2})$/, '$1.$2,$3')
    if (length >= 9) return input.value = money.replace(/^(\d{2})(\d{3})(\d{2})$/, '$1.$2,$3')
    if (length >= 7) return input.value = money.replace(/^(\d{1})(\d{3})(\d{2})$/, '$1.$2,$3')
    if (length >= 6) return input.value = money.replace(/^(\d{3})(\d{2})$/, '$1,$2')
    if (length >= 5) return input.value = money.replace(/^(\d{2})(\d{2})$/, '$1,$2')
    if (length >= 3) return input.value = money.replace(/^(\d{1})(\d{2})$/, '$1,$2')

}

export { mask_cpf, mask_cnpj, mask_cep, mask_phone, mask_money, mask_input_money };