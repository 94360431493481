import React, {useState, useEffect} from "react";

import './mytable.css';

import { useTable, useSortBy, usePagination } from "react-table";

import Pagination from 'react-bootstrap/Pagination';

const MyTable = ({ data, columns }) => {

    const [inputSearch, setInputSearch] = useState('');
    const [dataFiltered, setDataFiltered] = useState(data);

    useEffect(() => {

        const filtered = data.filter((row) => {
            let keys = Object.keys(row);

            let ok = false;
            keys.forEach(key => {
                if (String(row[key]).toLowerCase().includes(inputSearch.toLowerCase())) {
                    ok = true;
                }
            });

            return ok;
        });

        setDataFiltered(filtered);

    }, [inputSearch, data]);

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

        } =
            useTable(
            {
                columns,
                data: dataFiltered,
                initialState: { pageIndex: 0, pageSize: 10 },
            },
            useSortBy, usePagination
            );


    const handlePageClick = (pageNumber) => {
        gotoPage(pageNumber);
    };

    return (
        <div class='table-responsive'>

            <div class="row mb-4">

                <div class="col-6">
                    <div class='ms-1 mb-1'>Pesquisar :</div>
                    <div class='d-flex align-itens-center'>
                        <input type='text' className="form-control" placeholder='Buscar alguma informação específica...' value={inputSearch} onChange={e => setInputSearch(e.target.value)}></input>
                    </div>
                </div>

            </div>

            <table className='text-center table table-striped table-bordered table-hover' {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr class="align-middle" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                <span>
                                    {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                                </span>
                            </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.length === 0 && (
                        <tr>
                            <td colSpan={columns.length}>Nenhum registro encontrado</td>
                        </tr>
                    )}
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr class="align-middle" {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {page.length !== 0 && pageCount > 1 && <Pagination className="rh-pagination">
                <Pagination.First onClick={() => handlePageClick(0)} />
                <Pagination.Prev onClick={() => previousPage()} />
                <Pagination.Item active={pageIndex === 0} onClick={() => handlePageClick(0)}>1</Pagination.Item>
                {pageOptions.map((pageNumber) => (

                    // se pageNumber for multiplo de 5, exibe o numero
                    pageNumber % 5 === 0 && pageNumber !== 0 && <Pagination.Item key={pageNumber} active={pageIndex === pageNumber} onClick={() => handlePageClick(pageNumber)}>{pageNumber}</Pagination.Item>

                ))}
                <Pagination.Item active={pageIndex === pageCount} onClick={() => handlePageClick(pageCount - 1)}>{pageCount}</Pagination.Item>
                <Pagination.Next onClick={() => nextPage()} />
                <Pagination.Last onClick={() => handlePageClick(pageCount - 1)} />
            </Pagination>}
        </div>
    );
};

export default MyTable;
